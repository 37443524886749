import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import MapsGoogle from "pages/Maps/MapsGoogle";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Pages404 from "pages/Utility/pages-404";
import DataTable from "react-data-table-component";
import { myParseFloat, validateEmail, validatePassword } from "../../helpers/validationHelper"
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
  CardBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  CardTitle,
  Spinner
} from "reactstrap"
import classnames from "classnames"

import ReactSwitch from "react-switch"
import Select from "react-select"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { getLaundry, postAddLaundry, postUpdateLaundry, postArea } from "helpers/LaundryHelper"
import { searchUsers, getUsers, filterUsers, updatePromoCode } from "../../store/actions";
import { getLaundrys, getLaundrydetails, deleteLaundry } from "../../store/laundry/actions"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom';
import google_map_logo from '../../assets/images/google_map_logo.png'
import Switch from "react-switch"
import { postAddUser, postUpdateUser } from "helpers/userHelper";
import { del, postWeb } from "helpers/api_helper";
import { Text } from "recharts";
import { set } from "lodash";
import moment from "moment";

const formatDate = (isoString) => {
  if (!isoString) return '';
  const date = new Date(isoString);
  return date.toISOString().split('T')[0];
};

const Laundry = props => {

  const defaultLoyaltyPoints = {
    online: false,
    offline: false
  };
  const defaultLoyaltyPointsOrderUsage = {
    online: false,
    offline: false
  };

  const discountChecked = (type, value) => {
    
    if (type === "areaDiscount") {
      setdiscountConfig({ ...discountConfig, status: value })
    }
    if (type === "areaFreeRechargeWallet") {
      setareadiscountConfig({ ...areadiscountConfig, status: value })
    }
  }

  const [LaundryModal, SetLaundryModal] = useState(false)
  const [loyaltyPoints, setLoyaltyPoints] = useState(defaultLoyaltyPoints);
  const [loyaltyPointsOrderUsage, setloyaltyPointsOrderUsage] = useState(defaultLoyaltyPointsOrderUsage);
  const [discount, setDiscount] = useState({ online: "", onlineChecked: false, offline: "", offlineChecked: false, areaDiscountChecked: false, areaDiscount: "" });
  const [permModal, SetPermModal] = useState(false)
  const [lat, SetLat] = useState("24.71894638877753")
  const [lng, SetLng] = useState("46.675559402959095")
  const [partnerSubscription, setPartnerSubscription] = useState(true)
  const [carpetWash, setCarpetWash] = useState(true)
  const [clothWash, setClothWash] = useState(true)
  const [showCustomerDiscount, setShowCustomerDiscount] = useState(true)
  const [status, setStatus] = useState(true)
  const [vatToggle, setVatToggle] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditItem, setIsEditItem] = useState({})
  const [userData, setUserData] = useState({});
  const [laundryListData, setLaundryListData] = useState([]);

  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [Commission, setCommission] = useState("");
  const [VatPercent, setVatPercent] = useState("");
  const [Type, setType] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [areaVal, setAreaVal] = useState([]);
  const [laundryDeleteId, setLaundryDeleteId] = useState(0);
  const [areaOption, setAreaOption] = useState([]);
  const [addressAr, setAddressAr] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [shortName, setShortName] = useState("");
  const [crNumber, setCrNumber] = useState("");
  const [error, setError] = useState({});
  const [activeTab, setactiveTab] = useState("1");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userRoleOptions, setUserRoleOptions] = useState([])
  const [searchText, setSearchText] = useState("")
  const [laundryUser, setLaundryUser] = useState({})
  const [discountConfig, setdiscountConfig] = useState({})
  const [areadiscountConfig, setareadiscountConfig] = useState({})
  const [saveConfirm, setSaveConfirm] = useState(false)

  const [useAreaPriceIfLow, setUseAreaPriceIfLow] = useState(false)
  const { LaundryDetails, LaundryList, users, roles, filteredUsers } = useSelector(state => ({
    LaundryList: state.Laundry.LaundryList,
    LaundryDetails: state.Laundry.LaundryDetails,
    roles: state.permission.permissions,
    users: state.Users.users,
    filteredUsers: state.Users.filteredUsers
  }))

  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  const filterItem = (item) => {
    if (fetchStatusTrue && fetchStatusFalse) {
      return true
    } else if (fetchStatusTrue && !fetchStatusFalse) {
      return item.status
    } else if (fetchStatusFalse && !fetchStatusTrue) {
      return !item.status
    } else {
      return false
    }
  }

  const handleOrderLoyaltyPoints =(type)=>{
    setloyaltyPointsOrderUsage({...loyaltyPointsOrderUsage, [type]:!loyaltyPointsOrderUsage[type] })
   
  }
  const [selectedArea, setSelectedArea] = React.useState()
  const [areas, setAreas] = React.useState([])

  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
    setAreaOption(
      AreaResponse?.record?.map((item) => {
        return { label: item?.name, value: item?._id }
      })
    )
  }, [])

  useEffect(() => {
   if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getLaundrys({ web: true }))
  }, [])

  useEffect(() => {
    let userRoles = [];
    if (userData?.permissions?.allPermissions) {
      roles?.map((item) => {
        if (item._id != "637cb53f63f5416964433181") {
          userRoles.push({ label: item.name, value: item._id })
        }
      })
    } else if (userData?.permissions?.users?.validations?.createRoles) {
      roles?.map((item) => {
        if (userData?.permissions?.users?.validations?.createRoles?.includes(item._id)) {
          userRoles.push({ label: item.name, value: item._id })
        }
      })
    }
    setUserRoleOptions(userRoles)
  }, [userData])

  useEffect(() => {
    if (!vatToggle) {
      setVatPercent("")
    }
  }, [vatToggle])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    setLaundryListData(LaundryList)
  }, [LaundryList])

  const handleSearch = (val) => {

    val = val?.toLowerCase()
    let filterBySearch = LaundryList.filter((item) => item?.nameEn?.toLowerCase()?.includes(val) || item?.areas?.findIndex((data) => data?.name?.toLowerCase()?.includes(val)) !== -1 || (item?.status && val == "on") || (!item?.status && val == "off") || JSON.stringify(item?.vatPercent).includes(val));
    if (!val) {
      setLaundryListData(LaundryList)
    } else {
      setLaundryListData(filterBySearch)
    }
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  function getLocation() {
    setLaundryUser("");
    setIsEditItem({});
    setEmail("");
    setPassword("");
    SetLaundryModal(true)
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(showPosition);
    // }
  }

  function showPosition(position) {
    SetLat(position.coords.latitude);
    SetLng(position.coords.longitude);
  }

  useEffect(() => {
    if (!LaundryModal) {
      setNameEn("")
      setError({})
      setNameAr("")
      setCommission("")
      setVatPercent("")
      setType("")
      setAreaVal([]);
      setAddressAr("")
      setAddressEn("")
      setShortName("")
      setCrNumber("")
      setVatToggle(true)
      setStatus(true)
      setCarpetWash(true)
      setClothWash(true)
      setPartnerSubscription(true)
      setUseAreaPriceIfLow(false)
      SetLng("46.675559402959095")
      SetLat("24.71894638877753")
      setIsEdit(false);
      setEmail("");
      setPassword("");
      setloyaltyPointsOrderUsage(defaultLoyaltyPointsOrderUsage)
    }
    if (LaundryModal) {
      setactiveTab("1");

      const _searchText = searchText;
      const _password = password;
      const _email = email;
      setTimeout(() => {
        setSearchText(_searchText);
        setPassword(_password);
        setEmail(_email);
        setError({
          ...error,
          password: false
        })
        if (isEditItem?._id != undefined) {
          //get user details of selected Laundry
          dispatch(filterUsers({
            web: true,
            laundry: isEditItem._id
          }));
        }

      }, 1000);
    }
  }, [LaundryModal])

  //update area in api

  useEffect(() => {
    let index = LaundryList.findIndex((item) => item._id === laundryDeleteId);
    if (laundryDeleteId && index === -1) {
      toastr.success("Laundry has been Deleted", "Laundry Deleted");
    }
  }, [LaundryList])

  // useEffect(async () => {
  //   const AreaResponse = await postArea({});
  //   setAreaList(AreaResponse?.record);
  //   setAreaOption(
  //     AreaResponse?.record?.map((item) => {
  //       return { label: item?.name, value: item?._id }
  //     })
  //   )
  // }, [])

  const handleOnchange = (val) => {
    setAreaVal(val ? val.split(",") : [])
  }

  const GET_AREA = async () => {
    getLocation()
  }

  const UPDATE_LAUNDRY = (item) => {
    setdiscountConfig(item?.areaDiscount)
    setareadiscountConfig(item?.areaFreeRechargeWallet)
    setNameEn(item.nameEn)
    setNameAr(item.nameAr)
    setCommission(item.commission == 0 ? "0" : item.commission)
    setVatPercent(item.vatNumber)
    setType("franchise_laundry")
    setAreaVal(item.areas.map((item2) => item2._id));
    setAddressAr(item.addressAr)
    setAddressEn(item.addressEn)
    setShortName(item.shortName)
    setCrNumber(item.crNumber)
    setVatToggle(item.vatPercent == 15)
    setStatus(item.status)
    setCarpetWash(item.enableCarpet)
    setClothWash(item.enableCloths)
    setShowCustomerDiscount(item.showCustomerAppDiscount)
    setPartnerSubscription(item.enablePartnerPackage)
    setUseAreaPriceIfLow(item.useAreaPriceIfLow)
    SetLng(item.longitude ? item.longitude : "46.675559402959095")
    SetLat(item.latitude ? item.latitude : "24.71894638877753")
    setIsEdit(true);
    setIsEditItem(item)
    setLaundryUser({});
    SetLaundryModal(true)
    setLoyaltyPoints({ ...defaultLoyaltyPoints, online: item?.loyaltyPoints?.online, offline: item.loyaltyPoints?.offline })
    setloyaltyPointsOrderUsage({...loyaltyPointsOrderUsage, online: item?.useLoyaltyBalance?.online, offline: item.useLoyaltyBalance?.offline})
    
    
  }

  var node = useRef();
  const keyField = "id";
  const { SearchBar } = Search;
  const sizePerPage = 10;

  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: laundryListData.length, // replace later with size(users),
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  let LaundryListColumns = [
    {
      name: "Name (English)",
      sortable: true,
      selector: row => row.nameEn,
      wrap: true,
    }, {
      name: "VatPercent",
      sortable: true,
      selector: row => row.vatPercent,
      center: true,
      wrap: true,
    }, {
      name: "Area",
      wrap: true,
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.areas?.map((item2, index) => {
            return `${item2?.name} |`
          })}
        </div>
      ),
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row?.status ? "On" : "Off"}
        </div>
      ),

      // eslint-disable-next-line react/display-name
    },
  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.laundries?.update == true) {
    LaundryListColumns.push(
      {
        name: "View",
        center: true,
        selector: row => {
          return <>
            <button onClick={() => UPDATE_LAUNDRY(row)} className="btn btn-primary btn-sm" >
              View
            </button>
          </>
        }
      });
  }

  function toggleLaundryModal() {
    SetLaundryModal(!LaundryModal);
    removeBodyCss();
  }

  function togglePermModal() {
    SetPermModal(!permModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000
  };


  const handleValidSubmit = async (event, values) => {
    if (areaVal?.length < 1) {
      setError({ area: "Area Required" });
      return
    }
    const AddData = {
      nameEn: nameEn,
      nameAr: nameAr,
      commission: parseFloat(Commission).toFixed(2),
      type: "franchise_laundry",
      vatPercent: vatToggle ? 15 : 0,
      areas: areaVal,
      latitude: lat,
      longitude: lng,
      addressAr: addressAr,
      addressEn: addressEn,
      shortName: shortName,
      crNumber: crNumber,
      status: status,
      enablePartnerPackage: partnerSubscription,
      enableCloths: clothWash,
      enableCarpet: carpetWash,
      showCustomerAppDiscount: showCustomerDiscount,
      useLoyaltyBalance:loyaltyPointsOrderUsage,
      useAreaPriceIfLow: useAreaPriceIfLow,
    }
    if (vatToggle) {
      AddData['vatNumber'] = VatPercent;
    }
    let LaundryResponse;
    let resTitle, resMsg;
    if (isEdit) {
      AddData["_id"] = isEditItem._id;
      LaundryResponse = await postUpdateLaundry(AddData)
      resTitle = "Laundry";
      resMsg = LaundryResponse.statusCode == 200 ? "Laundry Updated" : "Unable to Update Laundry";
    } else {
      AddData["billNo"] = 1000;
      LaundryResponse = await postAddLaundry(AddData);
      resTitle = "Laundry";
      resMsg = LaundryResponse.statusCode == 200 ? "Laundry Added" : "Unable to Add Laundry";
      setIsEdit(true);
    }

    if (!isEdit) {
      //Show user details
      const newLaundryData = LaundryResponse?.record;
      setIsEditItem(newLaundryData);
      setactiveTab("2");
      setPassword("");
      setEmail("");
      setError({
        ...error,
        password: false,
        email: false
      })
    }
    else {
      setError({
        ...error,
        password: false
      })
    }

    getLaundry();
    dispatch(getLaundrys({ web: true }));
    if (LaundryResponse.statusCode == 200) {
      toastr.success(resMsg, resTitle);
    } else {
      toastr.error(resMsg, resTitle);
    }
  }

  const updatePermissions = async () => {
    togglePermModal();
  }

  const dispatch = useDispatch();

  useEffect(() => {
    getLaundry()
  }, [dispatch]);

  useEffect(() => {
    if (!password)
      return;

    let error = false;
    if (password && password.trim() == "") {
      if (!laundryUser?._id) {
        error = true;
      }
    }
    else {
      if (!validatePassword(password)) {
        error = true;
      }
    }
    setError({
      ...error,
      password: error
    })

  }, [password]);

  useEffect(() => {
    if (!email)
      return

    if (!validateEmail(email)) {
      setError({
        ...error,
        email: true
      })
    }
    else {
      setError({
        ...error,
        email: false
      })
    }
  }, [email])


  useEffect(async () => {
    if (LaundryDetails?.areas?.length > 0) {
      const AreaResponse = await postArea({});
      setAreaList(AreaResponse?.record);
    }
  }, [LaundryDetails]);


  const addUpdateLoyaltyPoints = async (category) => {
    let data = {}
    if (category === "online") {
      await setLoyaltyPoints({ ...loyaltyPoints, online: !loyaltyPoints?.online })
      data.loyaltyPoints = {}
      data.loyaltyPoints.online = loyaltyPoints?.online == true ? false : true
      data.loyaltyPoints.offline = loyaltyPoints.offline
    }
    else {
      await setLoyaltyPoints({ ...loyaltyPoints, offline: !loyaltyPoints?.offline })
      data.loyaltyPoints = {}
      data.loyaltyPoints.offline = loyaltyPoints?.offline == true ? false : true
      data.loyaltyPoints.online = loyaltyPoints.online
    }
    data._id = isEditItem._id
    let LaundryResponse = await postUpdateLaundry(data)
    let resTitle = "Laundry Loyalty ";
    let resMsg = LaundryResponse.statusCode == 200 ? "Laundry's Loyalty Points Settings Updated" : "Unable to Update Loyalty Point Setting. Error : {response.msg}";
    if (LaundryResponse.statusCode === "200") {
      dispatch(getLaundrys({ web: true }))
      setLaundryListData(laundryListData)
    }
    LaundryResponse.statusCode === "200" ? toastr.success(resMsg, resTitle) : toastr.error("Unable to update", resTitle)
  }
  const handleDiscountCheck = async (type, status) => {
    let data;
    if (type == "rechargeWallet") {
      discountChecked('areaFreeRechargeWallet', !areadiscountConfig?.status)
      data = {
        status: status,
        _id: areadiscountConfig?._id ? areadiscountConfig?._id : null,
      }
      if (discountConfig?.count)  {
        data["count"]=discountConfig?.count
      }
    }
    else {
      discountChecked('areaDiscount', status)
      data = {
        status: status,
        _id: discountConfig?._id ? discountConfig?._id : null,
      }
      if (discountConfig?.count)  {
      data["count"]=discountConfig?.count
      }
    }
    if(data._id == null){
      toastr.error("For this Laundry Prmocode is not added")
      return 
    }
   dispatch(updatePromoCode(data));
   dispatch(getLaundrys({ web: true }))
   setLaundryListData(laundryListData)
  }
 

  const addUpdateUser = async () => {
    let valid = true;
    let _validObj = { email: false, password: false };
    //Validate user details
    if (!validateEmail(email)) {
      valid = false;
      _validObj.email = true;
    }

    if (password && password.trim() == "" || !password) {
      if (!laundryUser?._id) {
        valid = false;
        _validObj.password = true;
      }
    }
    else {
      if (!validatePassword(password)) {
        valid = false;
        _validObj.password = true;
      }
    }

    setError({
      ...error,
      ..._validObj
    });

    if (!valid) {
      return;
    }

    let userResponse = {};
    if (laundryUser?._id) {
      const user = {
        _id: laundryUser._id,
        email: email,
        laundry: isEditItem._id
      }
      if (password && password.trim() != "") {
        user.password = password;
      }
      userResponse = await postUpdateUser(user);
    }
    else {
      userResponse = await postAddUser({
        email: email,
        password: password,
        laundry: isEditItem._id,
        role: "franchise_laundry",
        web: true
      });

      if (userResponse.statusCode == 200) {
        const _laundryUser = userResponse.record;
        _laundryUser.laundry = isEditItem._id;
        setLaundryUser(_laundryUser);
      }
    }

    if (userResponse.statusCode == 200) {
      toastr.success(laundryUser?._id ? "User Updated Successfully" : "User Added Successfully", "Success");
      SetLaundryModal(false);
    }
    else {
      toastr.error(userResponse?.msg ? userResponse?.msg : "Error in saving user details", "Error");
    }



  }



  useEffect(() => {
    handleSearch(searchText);
  }, [searchText])

  useEffect(() => {
    if (filteredUsers.length > 0) {
      setEmail(_email => filteredUsers[0]?.email);
      setLaundryUser(filteredUsers[0]);
    }
  }, [filteredUsers]);

  //Delivery Fee code
  const [deliveryFee, setDeliveryFee] = useState({});
  const [originalDeliveryFee, setOriginalDeliveryFee] = useState(0);
  const [deliveryFeeError, setDeliveryFeeError] = useState(false);
  const [customerCounts, setCustomerCounts] = useState({});
  const [firstMonthVal, setFirstMonthVal] = useState({});
  const [secondMonthVal, setSecondMonthVal] = useState({});
  const [startDate, setStartDate] = useState({});
  const [selectedRanges, setSelectedRanges] = useState({});
  const [actualDBData, setActualDBData] = useState({});
  const [selectedSizeRange, setSelectedSizeRange] = useState(null);

  //total customer count for each user type
  const totalCustomerCount = (userType) => {
    let total = 0;
    Object.values(customerCounts[userType] || {}).forEach((count) => {
      total += count;
    });
    return total;
  };


  useEffect(() => {
    // Initialize selectedRanges based on startDate state
    const initialSelectedRanges = {};
    userTypes.forEach((userTypeObj) => {
      const userType = Object.keys(userTypeObj)[0];
      initialSelectedRanges[userType] = [];
  
      Object.entries(userTypeObj[userType]).forEach(([sizeRange]) => {
        if (startDate[userType]?.[sizeRange]) {
          initialSelectedRanges[userType].push(sizeRange);
        }
      });
    });
  
    setSelectedRanges(initialSelectedRanges);
  }, [startDate]);


  const laundryId = isEditItem._id;

  useEffect(() => {
    if (isEditItem?._id && deliveryFee) {
      const initialFirstMonthVal = {};
      const initialSecondMonthVal = {};
      const initialjourneyStartDate = {};
      for (const userType in deliveryFee) {
        initialFirstMonthVal[userType] = {};
        initialSecondMonthVal[userType] = {};
        initialjourneyStartDate[userType] = {};
        for (const sizeRange in deliveryFee[userType]) {
          const convertedKey = sizeRange.replace("+", "plus");
          initialFirstMonthVal[userType][convertedKey] = deliveryFee[userType][sizeRange]?.firstMonth;
          initialSecondMonthVal[userType][convertedKey] = deliveryFee[userType][sizeRange]?.secondMonth;
          initialjourneyStartDate[userType][convertedKey] = deliveryFee[userType][sizeRange]?.startDate;
        }
      }
      setFirstMonthVal(initialFirstMonthVal);
      setSecondMonthVal(initialSecondMonthVal);
      setStartDate(initialjourneyStartDate);
    }
  }, [isEditItem, deliveryFee]);

  const handleOriginalDeliveryFeeChange = (value) => {
    if (!value) {
      value = 0;
    }
    setOriginalDeliveryFee(value);
  };

   // Function to update first month value
   const handleFirstMonthChange = (userType, sizeRange, value) => {
    if (!value) {
      value = 0;
    }
    setFirstMonthVal(prevState => ({
      ...prevState,
      [userType]: {
        ...prevState[userType],
        [sizeRange]: value
      }
    }));
  };

  // Function to update second month value
  const handleSecondMonthChange = (userType, sizeRange, value) => {
    if (!value) {
      value = 0;
    }
    setSecondMonthVal(prevState => ({
      ...prevState,
      [userType]: {
        ...prevState[userType],
        [sizeRange]: value
      }
    }));
  };


  const [isLoading, setLoading] = useState(false)

  const handleRowSaveClick = (userType, sizeRange) => {
    setSelectedSizeRange({ userType, sizeRange });
    setSaveConfirm(true);
  };
  
  const handleSave = () => {
    setLoading(true)
  if (!selectedSizeRange) {
    toastr.error("No row selected for saving.");
    return;
  }

  const firstMonthValue = firstMonthVal[selectedSizeRange.userType]?.[selectedSizeRange.sizeRange];
  const secondMonthValue = secondMonthVal[selectedSizeRange.userType]?.[selectedSizeRange.sizeRange];
  const startDateValue = startDate[selectedSizeRange.userType]?.[selectedSizeRange.sizeRange];

  //If 1st or second month value is 0 and that size range has a startDate, don't save. And show the error in toaster.
  if (startDateValue && (firstMonthValue == 0 || !firstMonthValue || secondMonthValue == 0 || !secondMonthValue)) {
    setLoading(false);
    setSaveConfirm(false);
    return toastr.error("First Month and Second Month values cannot be 0 for the selected size range.");
  }

  const { userType, sizeRange } = selectedSizeRange;
  const deliveryFee = convertStateToDeliveryFee();

  // Check if delivery fee exceeds the original delivery fee
  const fee = deliveryFee[userType]?.[sizeRange];
  if (fee && (fee.firstMonth > originalDeliveryFee || fee.secondMonth > originalDeliveryFee)) {
    setSaveConfirm(false);
    setLoading(false)
    setDeliveryFeeError(true);
    toastr.error(`Delivery Fee cannot exceed the original delivery fee. ${sizeRange.replace('_', '-') } for ${userType.toUpperCase()}`);
    return;
  } else {
    setDeliveryFeeError(false);
  }
  let filteredDeliveryFee = {}
  if(startDate[userType]?.[sizeRange]){
    filteredDeliveryFee = {
      [userType]: {
        [sizeRange]: {
          firstMonth: firstMonthVal[userType]?.[sizeRange],
          secondMonth: secondMonthVal[userType]?.[sizeRange],
          startDate: startDate[userType]?.[sizeRange] || "",
        },
      },
    };
  } else{
    filteredDeliveryFee = {
      [userType]: {
        [sizeRange]: null,
      },
    };
  }
  // Prepare filtered data for the selected size range only
  const actualDBDataString = actualDBData?.[userType]?.[sizeRange] ? JSON.stringify(actualDBData?.[userType]?.[sizeRange]) : '';
  const filteredDeliveryFeeString = filteredDeliveryFee?.[userType]?.[sizeRange] ? JSON.stringify(filteredDeliveryFee?.[userType]?.[sizeRange]) : '';
    if(actualDBDataString === filteredDeliveryFeeString ){  
      setSaveConfirm(false)
      setLoading(false)
    return toastr.error("No changes made");
    }


  postWeb(`laundries/deliveryFee`, { deliveryFee: filteredDeliveryFee, laundryId, originalDeliveryFee }).then((res) => {
    if (res.statusCode == 200) {
      setActualDBData(prev=>({
        ...prev,
        [userType]: {
          ...filteredDeliveryFee?.[userType]
        }
      }))
      toastr.success(res.msg || "Delivery Fee Updated successfully");
    }
  }).catch((err) => {
    toastr.error("Error in updating Delivery Fee");
  }).finally(()=>{
    setSaveConfirm(false);
    setLoading(false)
  })
};


  const handleJourneyDateChange = (userType, sizeRange, value) => {
    setStartDate((prevState) => ({
      ...prevState,
      [userType]: {
        ...prevState[userType],
        [sizeRange]: value,
      },
    }));

    setSelectedRanges((prevState) => {
      const newState = { ...prevState };
      if (!newState[userType]) {
        newState[userType] = [];
      }
      // Check if value exists, add or remove sizeRange accordingly
      if (value) {
        if (!newState[userType].includes(sizeRange)) {
          newState[userType].push(sizeRange);
        }
      } else {
        newState[userType] = newState[userType].filter((range) => range !== sizeRange);
      }
      return newState;
    });
  };

  
  
  useEffect(() => {
    if (isEditItem?._id) {
      getDeliveryFee();
    }
  }, [isEditItem, LaundryModal]);

  const getDeliveryFee = () => {
    postWeb(`laundries/getDeliveryFee`, {laundryId}).then((res) => {
      if (res.statusCode == 200) {
        setDeliveryFee(res?.deliveryFee);
        setActualDBData(res?.deliveryFee);
        setOriginalDeliveryFee(res?.originalDeliveryFee);
        setCustomerCounts(res?.customerCounts);

        // Set startDate state here
      const initialStartDate = {};
      for (const userType in res?.deliveryFee) {
        initialStartDate[userType] = {};
        for (const sizeRange in res?.deliveryFee[userType]) {
          const backendStartDate = res?.deliveryFee[userType][sizeRange]?.startDate; // "2024-07-24T00:00:00.000Z"
          let formattedStartDate = null
          if(backendStartDate){
            formattedStartDate = backendStartDate.substring(0, 10); // "2024-07-24"
          } 
          initialStartDate[userType][sizeRange] = formattedStartDate;
        }
      }
        setStartDate(initialStartDate);
        console.log("Delivery RES:: ", res)
      }
    }).catch((err) => {
      toastr.error("Error in fetching Delivery Fee");
    });
  };

  const calculateTotalRevenue = (userType, month) => {
    let totalRevenue = 0;
    Object.entries(userTypes.find(obj => Object.keys(obj)[0] === userType)[userType]).forEach(([sizeRange, fee]) => {
      const customerCount = customerCounts[userType]?.[sizeRange] || 0;
      const value = month === 'first' ? firstMonthVal[userType]?.[sizeRange] || 0 : secondMonthVal[userType]?.[sizeRange] || 0;
      totalRevenue += customerCount * value;
    });
    return totalRevenue;
  };
  


    const convertStateToDeliveryFee = () => {
      const convertedDeliveryFee = {};
      for (const userType in firstMonthVal) {
        convertedDeliveryFee[userType] = {};
        for (const sizeRange in firstMonthVal[userType]) {
          const convertedKey = sizeRange.replace("+", "plus");
          convertedDeliveryFee[userType][convertedKey] = {
            firstMonth: firstMonthVal[userType][sizeRange],
            secondMonth: secondMonthVal[userType][sizeRange],
            startDate: startDate[userType]?.[sizeRange] || "" // Ensure date is included or empty
          };
        }
      }
     return convertedDeliveryFee;
    };
    
  

    const handleCancel = () => {
      //close model without changing values
      setDeliveryFee(actualDBData);
      toggleLaundryModal();
    }

  const userTypes = [
    {
      loyal: {
        "0_25": 5,
        "25_50": 10,
        "50_75": 15,
        "75_100": 20,
        "100_125": 25,
        "125_150": 30,
        "150_175": 35,
        "175plus": 40,
      },
    },
    {
      regular: {
        "0_25": 10,
        "25_50": 15,
        "50_75": 20,
        "75_100": 25,
        "100_125": 30,
        "125_150": 35,
        "150_175": 40,
        "175plus": 45,
      },
    },
    {
      rookie: {
        "0_25": 15,
        "25_50": 20,
        "50_75": 25,
        "75_100": 30,
        "100_125": 35,
        "125_150": 40,
        "150_175": 45,
        "175plus": 50,
      },
    },
    {
      bouncer: {
        "0_25": 20,
        "25_50": 25,
        "50_75": 30,
        "75_100": 35,
        "100_125": 40,
        "125_150": 45,
        "150_175": 50,
        "175plus": 55,
      },
    },
  ];


  if (userData?.permissions?.allPermissions == true || userData?.permissions?.laundries?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>LAUNDRY | Kleen Admin Panel</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Home")}
              breadcrumbItem={props.t("laundry")}
            />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="6">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative d-flex">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          autoComplete={false}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className="ms-2" style={{ width: "20rem" }}>
                          <Select
                            placeholder="All Areas"
                            onChange={val => {
                              setSelectedArea(val)
                            }}
                            isClearable={true}
                            options={areas}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm="6" className="text-end d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mx-3">
                        <span className={`me-2 ${fetchStatusTrue ? "text-success font-weight-semibold" : ""}`}>Show Enabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusTrue(!fetchStatusTrue) }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className={`me-2 ${fetchStatusFalse ? "text-success font-weight-semibold" : ""}`}>Show Disabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusFalse(!fetchStatusFalse) }}
                          />
                        </div>
                      </div>
                    </div>
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.laundries?.create == true) ?
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  "
                        onClick={() => GET_AREA()}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Laundry
                      </Button>
                      : null
                    }
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={LaundryListColumns}
                    data={laundryListData
                      .filter((item) => {
                        return filterItem(item)
                      }).filter(slot => {
                        if (!selectedArea) {
                          return true
                        }
                        console.log(slot, selectedArea)
                        return slot.areas
                          .map(area => area._id)
                          .includes(selectedArea?.value)
                      })
                    }
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <div>
              <Modal
                size="lg"
                isOpen={LaundryModal}
                toggle={() => {
                  toggleLaundryModal();
                }}
                backdrop="static"
                keyboard={false}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    <span >{isEdit ? "Update Laundry" : "Create Laundry"}</span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      SetLaundryModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <Nav tabs className="mt-2 ms-2">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1")
                      }}
                    >
                      Laundry Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2")
                      }}
                      disabled={!isEditItem?._id}
                    >
                      User
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3")
                      }}
                      disabled={!isEditItem?._id}
                    >
                      Loyalty Points
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4",
                      })}
                      onClick={() => {
                        toggle("4")
                      }}
                      disabled={!isEditItem?._id}
                    >
                      Discount Configuration
                    </NavLink>
                  </NavItem>
                  {/* Delivery Fee */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "5",
                      })}
                      onClick={() => {
                        toggle("5")
                      }}
                      disabled={!isEditItem?._id}
                    >
                      Delivery Fee
                    </NavLink>
                  </NavItem>

                </Nav>
                <div className="modal-body">
                  <TabContent activeTab={activeTab} className="p-1 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <AvForm
                              onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                              }}
                            >
                              <div className="row mb-2">
                                <Col sm={6}>
                                  <Label htmlFor="horizontal-firstname-Input" className="col-sm-4 col-form-label">
                                    Name (English) <span className="mandatory_feild">*</span>
                                  </Label>
                                  <AvField
                                    type="text"
                                    onChange={(e) => { setNameEn(e.target.value) }}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="NameEn"
                                    name="nameEn"
                                    errorMessage="Please Enter Name (English)"
                                    validate={{ required: { value: true } }}
                                    value={nameEn}
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-4 col-form-label"
                                  >
                                    Name (Arabic) <span className="mandatory_feild">*</span>
                                  </Label>
                                  <AvField
                                    type="text"
                                    onChange={(e) => { setNameAr(e.target.value) }}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="NameAr"
                                    name="nameAr"
                                    errorMessage="Please Enter Name (Arabic)"
                                    validate={{ required: { value: true } }}
                                    value={nameAr}
                                  />
                                </Col>
                              </div>
                              <div className="row mb-2">
                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-6 col-form-label"
                                  >Short Name <span className="mandatory_feild">*</span></Label>
                                  <AvField
                                    type="text"
                                    onChange={(e) => { setShortName(e.target.value) }}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="ShortName"
                                    name="shortName"
                                    errorMessage="Please Enter Short Name"
                                    validate={{ required: { value: true } }}
                                    value={shortName}
                                  />
                                </Col>

                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Commission <span className="mandatory_feild">*</span>
                                  </Label>
                                  <AvField
                                    type="number"
                                    onChange={(e) => { setCommission(e.target.value) }}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="Commission"
                                    name="commission"
                                    min={0}
                                    max={100}
                                    pattern="^[.0-9]*$"
                                    errorMessage="Please Enter 0-100 valid Commission"
                                    validate={{ required: { value: true }, min: 0 }}
                                    value={Commission}
                                  />
                                </Col>
                              </div>


                              <div className="row mb-2">
                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-6 col-form-label"
                                  >CR No. <span className="mandatory_feild">*</span></Label>
                                  <AvField
                                    type="text"
                                    onChange={(e) => { setCrNumber(e.target.value) }}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="CrNo"
                                    name="crNumber"
                                    errorMessage="Please Enter CR No."
                                    validate={{ required: { value: true } }}
                                    value={crNumber}
                                  />
                                </Col>


                                <Col sm={6}>
                                  <div className="d-flex col-sm-12">
                                    <Label htmlFor="horizontal-firstname-Input" className="col-sm-1 col-form-label">
                                      VAT <span className="mandatory_feild">*</span>
                                    </Label>
                                    <div className="col-sm-9 form-check form-switch">
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        onColor="#626ed4"
                                        onChange={(e) => setVatToggle(!vatToggle)}
                                        checked={vatToggle}
                                      />
                                    </div>
                                  </div>
                                  <AvField
                                    type="number"
                                    disabled={!vatToggle}
                                    onChange={(e) => setVatPercent(e.target.value)}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="Vat Number"
                                    name="vatPercent"
                                    min={1}
                                    minLength={15}
                                    maxLength={15}
                                    pattern="^[0-9]*$"
                                    errorMessage="Enter 15 digit valid VAT number"
                                    validate={{ required: { value: vatToggle } }}
                                    value={VatPercent}
                                  />
                                </Col>
                              </div>



                              <div className="row mb-2">



                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Areas <span className="mandatory_feild">*</span>
                                  </Label>
                                  <MultiSelect
                                    defaultValue={areaVal.toString()}
                                    className="multi-selects form-selects2 form-controlss areas-box"
                                    onChange={handleOnchange}
                                    options={areaOption}
                                  />
                                  {
                                    error?.area ? (
                                      <label style={{ color: "red" }}>{error?.area}</label>
                                    ) : null
                                  }
                                </Col>

                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Location <span className="mandatory_feild">*</span>
                                  </Label>
                                  <Button
                                    className="primary google_map"
                                    onClick={togglePermModal}
                                  >
                                    <img src={google_map_logo} alt="" />
                                  </Button>
                                  <Row>
                                    <Col sm={6} className="d-flex latitudes">
                                      <Label>Latitude </Label>
                                      <AvField
                                        disabled
                                        type="text"
                                        className="form-control"
                                        id="horizontal-firstname-Input"
                                        value={lat}
                                        // placeholder="VatPercent"
                                        name="latitude"
                                        errorMessage="Please Select Location"
                                        validate={{ required: { value: true } }}
                                      />
                                    </Col>
                                    <Col sm={6} className="d-flex latitudes">
                                      <Label>Longitude</Label>
                                      <AvField
                                        disabled
                                        type="text"
                                        className="form-control"
                                        id="horizontal-firstname-Input"
                                        value={lng}
                                        // placeholder="VatPercent"
                                        name="longitude"
                                        errorMessage="Please Select Location"
                                        validate={{ required: { value: true } }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </div>



                              <div className="row mb-2">
                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-6 col-form-label"
                                  >Address On Invoice(English) <span className="mandatory_feild">*</span></Label>
                                  <AvField
                                    type="text"
                                    onChange={(e) => { setAddressEn(e.target.value) }}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="AddressEn"
                                    name="addressEn"
                                    errorMessage="Please Enter Address On Invoice(English)"
                                    validate={{ required: { value: true } }}
                                    value={addressEn}
                                  />
                                </Col>


                                <Col sm={6}>
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-6 col-form-label"
                                  >Address On Invoice(Arabic) <span className="mandatory_feild">*</span></Label>
                                  <AvField
                                    type="text"
                                    onChange={(e) => { setAddressAr(e.target.value) }}
                                    className="form-control"
                                    id="horizontal-firstname-Input"
                                    placeholder="AddressAr"
                                    name="addressAr"
                                    errorMessage="Please Enter Address On Invoice(Arabic)"
                                    validate={{ required: { value: true } }}
                                    value={addressAr}
                                  />
                                </Col>
                              </div>
                              <div className="row">
                                <Col sm={6} className="d-flex mt-2">
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-form-label col-md-6"
                                  >
                                    Enable Partner Subscription
                                  </Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => setPartnerSubscription(!partnerSubscription)}
                                      checked={partnerSubscription}
                                    />
                                  </div>
                                </Col>
                                <Col sm={6} className="mt-2">
                                  <div><Label className="col-form-label">Use Loyalty Balance</Label></div>
                                  <div className="d-flex align-items-center " style={{"marginLeft":"10px"}}>
                                    <Label className="col-form-labe ">Online Order</Label>
                                    
                                    <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => handleOrderLoyaltyPoints("online")}
                                      checked={loyaltyPointsOrderUsage?.online}
                                    />
                                  
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center mt-2 ml-5" style={{"marginLeft":"10px"}}>
                                    <Label className="col-form-labe ">Offline  Order</Label>
                                    
                                    <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => handleOrderLoyaltyPoints("offline")}
                                      checked={loyaltyPointsOrderUsage?.offline}
                                    />
                                  
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={6} className="d-flex mt-2">
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-form-label col-md-6"
                                  >
                                    Use Area Price if Price is Less
                                  </Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ED4"
                                      onChange={(e) => setUseAreaPriceIfLow(!useAreaPriceIfLow)}
                                      checked={useAreaPriceIfLow}
                                    />
                                  </div>
                                </Col>
                              </div>
                              <div className="row">
                                <Col sm={6} className="d-flex mt-2">
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-form-label col-md-6"
                                  >
                                    Enable Cloth Wash
                                  </Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => setClothWash(!clothWash)}
                                      checked={clothWash}
                                    />
                                  </div>
                                </Col>
                              </div>
                              <div className="row">
                                <Col sm={6} className="d-flex mt-2">
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-form-label col-md-6"
                                  >
                                    Enable Carpet Wash
                                  </Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => setCarpetWash(!carpetWash)}
                                      checked={carpetWash}
                                    />

                                  </div>
                                </Col>
                              </div>
                              
                              <div className="row">
                                <Col sm={6} className="d-flex mt-2">
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-form-label col-md-6"
                                  >
                                    Show Customer App Discount
                                  </Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => setShowCustomerDiscount(!showCustomerDiscount)}
                                      checked={showCustomerDiscount}
                                    />

                                  </div>
                                </Col>
                              </div>

                              <div className="row mb-2">
                                <Col sm={6} className="d-flex mt-2">
                                  <Label
                                    htmlFor="horizontal-password-Input"
                                    className="col-form-label col-md-6"
                                  >
                                    Status
                                  </Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => setStatus(!status)}
                                      checked={status}
                                    />
                                  </div>
                                </Col>
                              </div>

                              <div className="row justify-content-end">
                                <Col sm={12} className="col-sm-12 text-end">
                                  <div>
                                    <Button
                                      type="submit"
                                      color="primary"
                                    // className="w-md"
                                    // onClick={ADD_NEW_LAUNDRY}
                                    >
                                      {isEdit ? "Update Laundry" : "Add Laundry"}
                                    </Button>

                                    <button
                                      type="button"
                                      onClick={() => {
                                        toggleLaundryModal();
                                      }}
                                      className="m-1 btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      Close
                                    </button>

                                  </div>
                                </Col>
                              </div>

                            </AvForm>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">

                            <div className="row mb-2">

                              <Col sm={6}>
                                <Label
                                  htmlFor="horizontal-email-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Email
                                </Label>
                                <Input
                                  type="email"
                                  name="email"
                                  className={"form-control " + (error?.email ? "is-invalid" : "")}
                                  id="horizontal-email-Input"
                                  placeholder="Email"
                                  errorMessage="Invalid Email"
                                  autoComplete={false}
                                  value={email}
                                  onChange={(e) => { setEmail(e.target.value) }}

                                />
                                {
                                  error?.email ? (
                                    <div className="invalid-feedback">Enter valid email</div>
                                  ) : null
                                }
                              </Col>
                            </div>

                            <div className="row mb-2">
                              <Col sm={6}>
                                <Label
                                  htmlFor="horizontal-password-Input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Set Password
                                </Label>
                                <input
                                  type="password"
                                  name="pass"
                                  className={"form-control " + (error?.password ? "is-invalid" : "")}
                                  autoComplete={false}
                                  id="horizontal-password-Input"
                                  value={password}
                                  onChange={(e) => { setPassword(e.target.value) }}
                                  placeholder="Set New Password"
                                  required
                                  // validate={{ required: { value: true } }}
                                />

                                {
                                  error?.password ? (
                                    <div className="invalid-feedback">
                                      {(password && password.trim() == "" || !password) ? "Please enter password" : "Password must be at least 8 chars, 1 capital, 1 small letter, 1 number and 1 sepacial charater."}
                                    </div>
                                  ) : null
                                }

                              </Col>

                            </div>


                            <div className="row justify-content-end">
                              <Col sm={12} className="col-sm-12 text-end">
                                <div>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={addUpdateUser}
                                  >
                                    {laundryUser?._id ? "Update User" : "Add User"}
                                  </Button>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      toggleLaundryModal();
                                    }}
                                    className="m-1 btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>

                                </div>
                              </Col>
                            </div>

                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">

                            <div className="row mb-2">
                              <h5 className="mb-3"> <i className="bx bxs-star"></i> Get Loyalty Points</h5>
                              <p className="mb-3">Get loyalty points when the following type of order is paid: </p>
                              <div className="mb-1 d-flex align-items-center">
                                <Label>Online</Label>
                                <div className="form-check form-switch">
                                  <Switch
                                    height={25}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={() => addUpdateLoyaltyPoints("online")}
                                    checked={loyaltyPoints?.online}

                                  />
                                </div>

                              </div>
                              <div className="mb-1 d-flex align-items-center">
                                <div className="mb-1 mt-1 d-flex align-items-center">
                                  <Label>Offline</Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      height={25}
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={() => addUpdateLoyaltyPoints("offline")}
                                      checked={loyaltyPoints?.offline}
                                    />
                                  </div>
                                </div>

                              </div>

                            </div>

                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">

                            <div className="row mb-2">


                              <div className="d-flex align-items-center">
                                <Label><i className="bx bx-money"></i> Area Discount</Label>
                                <div className="form-check form-switch">
                                  <Switch
                                    height={25}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={(e) => handleDiscountCheck("areaDiscount", !discountConfig?.status)}
                                    checked={discountConfig?.status}
                                  />
                                </div>


                              </div>
                            </div>

                            <div className="row mb-2">
                              {
                                discountConfig?.status == true && discountConfig?.code ? <> <div className="d-flex align-items-center">
                                  <Label>Promocode Name - {discountConfig?.code} </Label>


                                </div>
                                  <div className="d-flex align-items-center">
                                    <Label>Promocode Discount - {discountConfig?.percent} % </Label>
                                  </div>
                                </> : !discountConfig?.code ?<div className="d-flex align-items-center error_valid">No Area Discount promocode added. </div> : null
                              }

                            </div>

                            <div className="row mb-2 mt-4  flex">
                              <hr />
                              <div className="">
                                <div className="d-flex align-items-center">
                                  <Label>Area Free RechargeWallet</Label>
                                  <div className="form-check form-switch">
                                    <Switch
                                      height={25}
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                      onChange={(e) => handleDiscountCheck("rechargeWallet", !areadiscountConfig?.status)}
                                      checked={areadiscountConfig?.status}
                                    />
                                  </div>

                                </div>
                                <div>
                                  {
                                    areadiscountConfig?.status == true && areadiscountConfig?.code ? <>
                                      <div className="d-flex align-items-center">
                                        <Label>Promocode Name - {areadiscountConfig?.code} </Label>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <Label>Promocode Amount - {areadiscountConfig?.amount} SR</Label>
                                      </div>
                                    </> : !areadiscountConfig?.code ? <div className="d-flex align-items-center error_valid">No Area Free Recharge wallet promocode added. </div> :null
                                  }


                                </div>
                              </div>

                            </div>


                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      {/* Delivery Fee UI */}
                      <Card>
                      <CardBody className="p-1 mb-1">
                        <div className="row p-0">
                            <div className="col-md-3 mx-2 pe-0">
                              <CardText className="d-flex align-items-center fs-5 fw-bold mb-1">
                                Original Delivery Fee:
                              </CardText>
                            </div>
                            <div className="col-md-3 mb-2">
                              <Input
                                className="w-50"
                                type="text"
                                min={0}
                                value={originalDeliveryFee}
                                onChange={(e) => {
                                  let value = parseFloat(e.target.value);
                                  if (value == NaN || value == "" || value == null || value == undefined) {
                                    value = 0;
                                  }
                                  handleOriginalDeliveryFeeChange(value);
                                }}
                              />
                            </div>
                            {deliveryFeeError && (
                              <div className="text-danger mt-2">Delivery fee&apos;s cannot exceed the Original Delivery Fee.</div>
                            )}

                          <div className="col-md-12">
                            {userTypes.map((userTypeObj, index) => {
                              const userType = Object.keys(userTypeObj)[0];
                              const title = userType;
                              return (
                                <div key={index}>
                                  <table className="table pt-0" key={title}>
                                    <thead>
                                      <tr>
                                        <th colSpan={6} className="p-0 w-100" style={{ borderBottom: 'none' }}>{title.toUpperCase()} ({totalCustomerCount(userType)})</th>
                                      </tr>
                                      <tr>
                                        <th style={{ width: '25%', textAlign: 'center' }}>Avg. Basket Size</th>
                                        <th style={{ width: '5%', textAlign: 'center' }}>Journey Action</th>
                                        <th style={{ width: '25%', textAlign: 'center' }}>Journey Start Date</th>
                                        <th style={{ width: '15%', textAlign: 'center' }}>Customer Count</th>
                                        <th style={{ width: '15%' }}>Delivery Fee (1st Month)</th>
                                        <th style={{ width: '10%', textAlign: "center" }}>Est. Revenue (1st Month)</th>
                                        <th style={{ width: '20%' }}>Delivery Fee (2nd Month)</th>
                                        <th style={{ width: '10%', textAlign: "center" }}>Est. Revenue (2nd Month)</th>
                                        <th style={{ width: '10%', textAlign: "center" }}>
                                          Save Changes
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(userTypeObj[userType]).map(([sizeRange, fee]) => {
                                        const range = sizeRange.split('_');
                                        const customerCount = customerCounts[userType]?.[sizeRange] || 0;
                                        const firstMonthRevenue = customerCount * (firstMonthVal[title]?.[sizeRange] || 0);
                                        const secondMonthRevenue = customerCount * (secondMonthVal[title]?.[sizeRange] || 0);
                                        return (
                                          <tr key={sizeRange}>
                                         
                                            <td style={{ textAlign: 'center' }} className="pr-1 pt-2 pb-0">{range[0].replace("plus", "+")}{range[1] ? ` - ${range[1].replace("plus", "+")}` : ""}</td>
                                           <td className="p-1 mb-0" style={{ textAlign: 'center', }}>
                                              {/* If operation manager or super admin give the relevent permissions */}
                                              <Button
                                              className={`py-2 startedBtn`}
                                              style={startDate[title]?.[sizeRange] && {
                                                cursor:"not-allowed !important",
                                              }}
                                              disabled={startDate[title]?.[sizeRange] && (userData?.permissions?.allPermissions == false || userData?.permissions?.deliveryFee?.create == true || userData?.permissions?.deliveryFee?.update == false)}
                                              outline={startDate[title]?.[sizeRange] ? true : false}
                                              color={startDate[title]?.[sizeRange]?.length ? "success" : "secondary"} 
                                              
                                              onClick={()=>{
                                                     if (startDate[title]?.[sizeRange]?.length) {
                                                      // Remove date if it exists
                                                      handleJourneyDateChange(title, sizeRange, null);
                                                    } else {
                                                      // Set current date if no date exists
                                                      const currentDate = new Date();
                                                      const formattedDate = currentDate.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"
                                                      handleJourneyDateChange(title, sizeRange, formattedDate);
                                                    }
                                              }}>
                                                {startDate[title]?.[sizeRange] ? "Started" :"Start"}
                                              </Button>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                             <span className="">
                                             {formatDate(startDate[title]?.[sizeRange]) || '--'}
                                             </span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>{customerCount}</td>
                                            <td style={{ textAlign: 'right' }} className="p-1">
                                              <Input
                                                className="w-100 no-spinners"
                                                style={{ textAlign: 'center' }}
                                                type="text"
                                                min={0}
                                                value={firstMonthVal[title]?.[sizeRange] || 0}
                                                disabled={selectedRanges[title] && !selectedRanges[title].includes(sizeRange)}
                                                onChange={(e) => {
                                                  let value = parseFloat(e.target.value);
                                                  if (value == NaN || value == "" || value == null || value == undefined) {
                                                    value = 0;
                                                  }
                                                  handleFirstMonthChange(title, sizeRange, value);
                                                }}
                                              />
                                            </td>
                                            <td style={{ color: "green", textAlign: "center" }}>{firstMonthRevenue} SR</td>
                                            <td style={{ textAlign: 'right' }} className="p-1">
                                              <Input
                                                className="w-100 no-spinners"
                                                style={{ textAlign: 'center' }}
                                                type="text"
                                                min={0}
                                                disabled={selectedRanges[title] && !selectedRanges[title].includes(sizeRange)}
                                                value={secondMonthVal[title]?.[sizeRange] || 0}
                                                onChange={(e) => {
                                                  let value = parseFloat(e.target.value);
                                                  if (value == NaN || value == "" || value == null || value == undefined) {
                                                    value = 0;
                                                  }
                                                  handleSecondMonthChange(title, sizeRange, value);
                                                }}
                                              />
                                            </td>
                                            <td style={{ color: "green", textAlign: "center" }}>{secondMonthRevenue} SR</td>
                                            <td className="p-1">
                                              <Button
                                              disabled={isLoading}
                                              color="primary" onClick={() => handleRowSaveClick(title, sizeRange)}>
                                                Save
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                    
                                    })}
                                      <tr>
                                        <td colSpan={5} className="text-end fw-bold">Total Est. Revenue: </td>
                                        <td style={{ color: "green", textAlign: "center" }} className="fw-bold">{calculateTotalRevenue(userType, "first")} SR</td>
                                        <td colSpan={1} className="text-end fw-bold">Total Est. Revenue: </td>
                                        <td style={{ color: "green", textAlign: "center" }} className="fw-bold">{calculateTotalRevenue(userType, "second")} SR</td>
                                      </tr>
                                     
                                    </tbody>
                                  </table>
                                  <hr />
                                
                                </div>
                              );
                            })}
                          </div>
                          <Modal
                                    size="md"
                                    isOpen={saveConfirm}
                                    toggle={() => setSaveConfirm(!saveConfirm)}
                                    centered
                                  >
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0" id="myModalLabel">
                                        <span >
                                          Save Changes
                                        </span>
                                      </h5>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setSaveConfirm(false);
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                <h6>
                                  {startDate?.[selectedSizeRange?.userType]?.[selectedSizeRange?.sizeRange]
                                    ? (
                                      <>
                                        Are you sure you want to save changes? This will Start Journey from <strong>{startDate[selectedSizeRange.userType][selectedSizeRange.sizeRange]}</strong> for Customers of <span style={{ textTransform: "capitalize" }}><strong>{selectedSizeRange?.userType}</strong></span> category for <strong>{selectedSizeRange?.sizeRange ? selectedSizeRange?.sizeRange?.replace('_', '-') : ''}</strong> segment.
                                      </>
                                    )
                                    : (
                                      <>
                                        This will pause Journey for Customers of <span style={{ textTransform: "capitalize" }}><strong>{selectedSizeRange?.userType}</strong></span> category for <strong>{selectedSizeRange?.sizeRange ? selectedSizeRange?.sizeRange?.replace('_', '-') : ''}</strong> segment.
                                      </>
                                    )
                                  }
                                </h6>

                                    </div>
                                    <div className="modal-footer">
                                      <Row>
                                        <Col lg="12">
                                          <Button
                                          disabled={isLoading}
                                            type="submit"
                                            color="primary"
                                            className="w-md"
                                            onClick={() => { handleSave() }}
                                          >
                                            {isLoading ? <Spinner size={'sm'}/> :"Yes"}
                                          </Button>
                                          <Button
                                            type="submit"
                                            className="w-md mx-2"
                                            onClick={() => setSaveConfirm(false)}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Modal>
                        </div>
                          <div className="mt-4">
                           
                            {deliveryFeeError && (
                              <div className="text-danger mt-2">Delivery fee&apos;s cannot exceed the Original Delivery Fee.</div>
                            )}
                            <Button color="secondary" className="ms-2" onClick={handleCancel}>
                              Cancel
                            </Button>
                          </div>
                      </CardBody>
                    </Card>
                    </TabPane>
                  </TabContent>
                </div>
         
              </Modal>

              {/** add location Modal */}
              <Modal
                size="lg"
                isOpen={permModal}
                toggle={() => {
                  togglePermModal();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Add Location
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      SetPermModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg="12">
                      <Card className="mb-0">
                        <CardBody>
                          <Form>

                            <div className="map-space">
                              <MapsGoogle lat={lat} lng={lng} SetLat={SetLat} SetLng={SetLng} />
                            </div>

                            <div className="row justify-content-end">
                              <Col sm={9}>
                                <div>
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="w-md"
                                    onClick={() => updatePermissions()}
                                  >
                                    Add Location
                                  </Button>

                                  <button
                                    type="button"
                                    onClick={() => {
                                      togglePermModal();
                                    }}
                                    className="m-1 btn btn-secondary "
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>

                                </div>
                              </Col>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">


                </div>
              </Modal>




            </div>
          </Container>
        </div>

      </React.Fragment>
    )
  } else {

    return <Pages404 />
  }

}
Laundry.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Laundry)



