import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { searchUsers } from "../../store/actions"
import { useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
const SidebarContent = props => {
  var data = JSON.parse(localStorage.getItem("authUser"));
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  const dispatch = useDispatch();
  const [fill, setFill] = useState({});
  const [userData, setUserData] = useState({});
  const [menuLoaded, setMenuLoaded] = useState(false);

  const { users } = useSelector(state => ({
    users: state.Users.users
  }))




  useEffect(() => {
    dispatch(searchUsers({ webPermissions: true }))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {

    if (userData && userData.permissions && !menuLoaded) {
      setMenuLoaded(true);
      initMenu();
    }
  }, [userData])


  const initMenu = () => {
    const pathName = props.location.pathname
    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }


  useEffect(() => {

  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>

        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.panelHome?.read == true ? (
                <li>
                  <Link to="/analytics" className="">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Home")}</span>
                  </Link>
                </li>
              ) : null
            }
            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.panelDailyReports?.read == true ? (
                <li>
                  <Link to="/daily-reports" className="">
                    <i className="bx bx-note"></i>
                    <span>{props.t("Daily Reports")}</span>
                  </Link>
                </li>
              ) : null

            }
            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.analytics?.read == true ? (
                <li>
                  <Link to="/analytics-charts" className="">
                    <i className="bx bxs-bar-chart-alt-2"></i>
                    <span>{props.t("Analytics")}</span>
                  </Link>
                </li>
              ) : null

            }
            {
              (userData?.permissions?.allPermissions == true || (userData?.permissions?.logisticDashboard?.read == true || userData?.permissions?.driverTracking?.read == true)) ? (

                <li>

                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-purchase-tag"></i>
                    <span>{props.t("Logistics")}</span>
                  </Link>


                  <ul className="sub-menu" aria-expanded="false">
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.logisticDashboard?.read == true) ?
                      <li>
                        <Link to="/logistic-dashboard" className="">
                          <i className="bx bx-trophy"></i>
                          <span>{props.t("Logistic Dashboard")}</span>
                        </Link>
                      </li>
                      : null
                    }

                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.driverTracking?.read == true) ?
                      <li>
                        <Link to="/driver-locations" className="">
                          <i className="bx bx-map"></i>
                          <span>{props.t("Driver Locations")}</span>
                        </Link>
                      </li>
                      : null
                    }
                  </ul>
                </li>
                // <li>
                //   <Link to="/logistic-dashboard" className="">
                //     <i className="bx bx-trophy"></i>
                //     <span>{props.t("Logistic Dashboard")}</span>
                //   </Link>
                // </li>
              ) : null
            }
            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.captains?.read == true ? (
                <li>
                  <Link to="/drivers" className="">
                    <i className="bx bx-cycling"></i>
                    <span>{props.t("Captains")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.settings?.read == true ? (
                <li>
                  <Link to="/settings" className="">
                    <i className="bx bx-cog"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>
              ) : null
            }

            {/* {
              userData?.permissions?.allPermissions == true || userData?.permissions?.customers?.read == true ? (
                <li>
                  <Link to="/customers" className="">
                    <i className="bx bx-user"></i>
                    <span>{props.t("Customers")}</span>
                  </Link>
                </li>
              ) : null
            } */}

            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.customers?.read == true || userData?.permissions?.customerOTP?.read == true ? <li>

                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-purchase-tag"></i>
                  <span>{props.t("Customers")}</span>
                </Link>


                <ul className="sub-menu" aria-expanded="false">
                  {(userData?.permissions?.allPermissions == true || userData?.permissions?.customers?.read == true) ?
                    <li>
                      <Link to="/customers">{props.t("Customers List")}</Link>
                    </li> : null
                  }

                  {(userData?.permissions?.allPermissions == true || userData?.permissions?.customerOTP?.read == true) ?
                    <li>
                      <Link to="/customers-otplist">{props.t("Customers OTP list")}</Link>
                    </li>
                    : null
                  }
                  {/* Customer Details */}
                  {
                    userData?.permissions?.allPermissions == true ? (
                      <li>
                        <Link to="/customer-details">{props.t("Customer Details")}</Link>
                      </li>
                    ) : null
                  }
                </ul>
              </li> : null

            }


            {
              userData?.permissions?.allPermissions == true
                || userData?.permissions?.orders?.read == true
                || userData?.permissions?.refund?.read == true
                || userData?.permissions?.whatsappOrders?.read == true ?
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-purchase-tag"></i>
                    <span>{props.t("Orders")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li>
                      <Link to="/all-orders">{props.t("All Orders")}</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/completed-orders">{props.t("Old Completed Orders")}</Link>
                    </li> */}
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.orders?.read == true) ?
                      <li>
                        <Link to="/completed-orders">{props.t("Completed Orders")}</Link>
                      </li> : null
                    }
                    {/* <li>
                      <Link to="/cancelled-orders">{props.t("Cancelled Orders")}</Link>
                    </li> */}
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.orders?.read == true) ?
                      <li>
                        <Link to="/cancelled-orders">{props.t("Cancelled Orders")}</Link>
                      </li>
                      : null
                    }
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.orders?.read == true) ?
                      <li>
                        <Link to="/ongoing-orders">{props.t("Ongoing Orders")}</Link>
                      </li>
                      : null
                    }

                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.orders?.read == true) ?
                      <li>
                        <Link to="/returned-orders">{props.t("Returned Orders")}</Link>
                      </li>
                      : null
                    }

                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.refund?.read == true) ?
                      <li>
                        <Link to="/refunds">{props.t("Refunds")}</Link>
                      </li> :
                      null
                    }

                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.whatsappOrders?.read == true) ?
                      <li>
                        <Link to="/create-order">{props.t("Whatsapp Orders")}</Link>
                      </li> :
                      null
                    }

                  </ul>
                </li>
                // <li>
                //   <Link to="/all-orders" className="">
                //     <i className="bx bx-basket"></i>
                //     {/* <span className="badge rounded-pill bg-info float-end">04</span> */}
                //     <span>{props.t("All Orders")}</span>
                //   </Link>
                // </li>
                : null
            }

            {/* {
              (fill?.panelPermissions?.laundries || data?.role === "admin") ? (
                <li>
                  <Link to="/laundries" className="">
                    <i className="bx bx-closet"></i>
                    <span>{props.t("Laundries")}</span>
                  </Link>
                </li>
              ) : null
            } */}

            {/* {
              (fill?.panelPermissions?.sliders || data?.role === "admin") ? (
                <li>
                  <Link to="/sliders" className="">
                    <i className="bx bx-closet"></i>
                    <span>{props.t("Sliders")}</span>
                  </Link>
                </li>
              ) : null
            } */}

            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.laundries?.read == true) ? (
                <li>
                  <Link to="/laundry" className="">
                    <i className="bx bx-receipt"></i>
                    <span>{props.t("Laundry")}</span>
                  </Link>
                </li>
              ) : null
            }
            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.b2bCustomer?.read == true) ? (
                <li>
                  <Link to="/b2blist" className="">
                    <i className="bx bx-user"></i>
                    <span>{props.t("b2b Customers")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.b2bProduct?.read == true) ? (
                <li>
                  <Link to="/b2bproducts" className="">
                    <i className="bx bxl-product-hunt"></i>
                    <span>{props.t("b2b Products")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.reports?.read == true) ? (
                <li>
                  <Link to="/monthly-reports" className="">
                    <i className="bx bx-receipt"></i>
                    <span>{props.t("Reports")}</span>
                  </Link>
                </li>
              ) : null
            }
            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.clothes?.read == true) ? (
                <li>
                  <Link to="/clothes" className="">
                    <i className="bx bx-closet"></i>
                    <span>{props.t("Clothes")}</span>
                  </Link>
                </li>
              ) : null
            }
            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.area?.read == true) ? (
                <li>
                  <Link to="/areas" className="">
                    <i className="bx bx-area"></i>
                    <span>{props.t("Areas")}</span>
                  </Link>
                </li>
              ) : null
            }
            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.serviceManagement?.read == true) ? (
                <li>
                  <Link to="/service-management" className="">
                    <i className="bx bx-calendar-event"></i>
                    <span>{props.t("Service Management")}</span>
                  </Link>
                </li>
              ) : null
            }
            {
              (userData?.permissions?.allPermissions == true || userData?.holidays?.area?.read == true) ? (
                <li>
                  <Link to="/holidays" className="">
                    <i className="bx bx-calendar-event"></i>
                    <span>{props.t("Holidays")}</span>
                  </Link>
                </li>
              ) : null
            }
            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.redeemPackages?.read == true) ? (
                <li>
                  <Link to="/redeem-packages" className="">
                    <i className="bx bx-trophy"></i>
                    <span>{props.t("Redeem Packages")}</span>
                  </Link>
                </li>
              ) : null
            }


            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.timeSlots?.read == true) ? (
                <li>
                  <Link to="/time-slots" className="">
                    <i className="bx bx-time"></i>
                    <span>{props.t("Time Slots")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              userData?.permissions?.allPermissions == true
                || userData?.permissions?.carWashOrders?.read == true
                || userData?.permissions?.loyaltyPoints?.read == true ?
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-car"></i>
                    <span>{props.t("Carwash Orders")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">

                    {
                      (userData?.permissions?.allPermissions == true || userData?.permissions?.carWashOrders?.read == true) ? (
                        <li>
                          <Link to="/car-wash-orders" className="">
                            {props.t("Car Wash (Subscription)")}
                          </Link>
                        </li>
                      ) : null
                    }
                    {
                      (userData?.permissions?.allPermissions == true || userData?.permissions?.loyaltyPoints?.read == true) ? (
                        <li>
                          <Link to="/car-wash-redeem" className="">

                            {props.t("Car Wash (LoyaltyPoints)")}
                          </Link>
                        </li>
                      ) : null
                    }
                  </ul>
                </li> : null
            }

            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.reasons?.read == true) ? (
                <li>
                  <Link to="/reasons" className="">
                    <i className="bx bxs-collection"></i>
                    <span>{props.t("Reasons")}</span>
                  </Link>
                </li>
              ) : null
            }



            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.subscriptionPackages?.read == true) ? (
                <li>
                  <Link to="/subscription-packages" className="">
                    <i className="bx bx-trophy"></i>
                    <span>{props.t("Subscription Packages")}</span>
                  </Link>
                </li>
              ) : null
            }




            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.notificationText?.read == true) ? (
                <li>
                  <Link to="/notification-texts" className="">
                    <i className="bx bx-car"></i>
                    <span>{props.t("Notification Text")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.announcement?.read == true) ? (
                <li>
                  <Link to="/announcements" className="">
                    <i className="fa fa-bullhorn h-4 w-4"  ></i>
                    <span>{props.t("Announcement")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.promoCode?.read == true) ? (
                <li>
                  <Link to="/promo-codes" className="">
                    <i className="bx bx-gift"></i>
                    <span>{props.t("Promo Codes")}</span>
                  </Link>
                </li>
              ) : null
            }


            {/* {
              (fill?.panelPermissions?.areas || data?.role === "admin") ? (
                <li>
                  <Link to="/areas" className="">
                    <i className="bx bx-map"></i>
                    <span>{props.t("Areas")}</span>
                  </Link>
                </li>
              ) : null

            } */}

            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.pricelist?.read == true ? (
                <li>
                  <Link to="/price-list" className="">
                    <i className="bx bxs-barcode"></i>
                    <span>{props.t("Pricelist")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.laundryCoupons?.read == true ? (
                <li>
                  <Link to="/laundry-coupons" className="">
                    <i className="bx bxs-coupon"></i>
                    <span>{props.t("Laundry Coupon")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              userData?.permissions?.allPermissions == true || userData?.permissions?.slider?.read == true ? (
                <li>
                  <Link to="/sliders" className="">
                    <i className="bx bxs-coupon"></i>
                    <span>{props.t("Sliders")}</span>
                  </Link>
                </li>
              ) : null
            }

            {
              (userData?.permissions?.allPermissions == true || userData?.permissions?.users?.read == true) ? (
                <li>
                  <Link to="/users" className="">
                    <i className="bx bxs-user"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>
              ) : null
            }

            {/* Driver locations */}
            {
              // userData?.permissions?.allPermissions == true || userData?.permissions?.driverLocations== true &&
              // <li>
              //   <Link to="/driver-locations" className="">
              //     <i className="bx bx-map"></i>
              //     <span>{props.t("Driver Locations")}</span>
              //   </Link>
              // </li>
            }

            {
              (userData?.permissions?.allPermissions == true) ?
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-toggle-right"></i>
                    <span>{props.t("Permission")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/role-permission">{props.t("Roles")}</Link>
                    </li>
                    <li>
                      <Link to="/module-permission">{props.t("Permissions")}</Link>
                    </li>
                  </ul>
                </li> : null

            }

            {/* Support Tools */}
            {
              (userData?.permissions?.allPermissions == true 
                || userData?.permissions?.supportCancelOrder?.update == true
                || userData?.permissions?.supportFreeMoney?.update == true
                || userData?.permissions?.supportChangeMobile?.update == true) ? (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-support"></i>
                    <span>{props.t("Support Tools")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {(userData?.permissions?.allPermissions == true) && 
                    <li>
                      <Link to="/freshdesk-analytics">{props.t("Freshdesk Analytics")}</Link>
                    </li>
                    }
                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.supportFreeMoney?.update == true) && 
                    <li>
                      <Link to="/freeMoneySupport">{props.t("Free Money Support")}</Link>
                    </li>
                    }
                   {(userData?.permissions?.allPermissions == true || userData?.permissions?.supportCancelOrder?.update == true) &&
                    <li>
                      <Link to="/cancel-order">{props.t("Cancel Order")}</Link>
                    </li>
                    }

                    {(userData?.permissions?.allPermissions == true || userData?.permissions?.supportChangeMobile?.update == true) &&
                    <li>
                      <Link to="/change-number">{props.t("Change Customer Mobile")}</Link>
                    </li>
                    }
                  </ul>
                </li>
              ) : null
            }

          {/* Audit Logs*/}
          {
            userData?.permissions?.allPermissions == true || userData.permissions?.auditLogs?.read == true ? (
              <li>
                <Link to="/audit-logs" className="">
                  <i className="bx bx-receipt"></i>
                  <span>{props.t("Audit Logs")}</span>
                </Link>
              </li>
            ) : null
          }

            <li>
              <Link to="/logout" className="">
                <i className="bx bx-power-off"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>

          </ul >


        </div >
      </SimpleBar >
    </React.Fragment >
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
