//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_LOGIN = "/users/panelLogin"  //login api
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//Records
export const GET_RECORD = "/orders/search"

//Records
export const GET_SEARCH_RECORD = "/orders/searchByFilter"

//promoscode
export const ADD_NEW_PROMOS_CODE = "/promocode/create"
export const UPDATE_PROMOS_CODE = "/promocode/update"

export const PROMOS_CODE_LIST = "/promocode/search"

export const GET_TRANSACTIONS = "/transactions/search"
//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders/search"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"
export const REASON_SEARCH = "/reasons/search"
export const REFUND_AMOUNT = "/orders/refund"

// PRICELIST
export const GET_PRICELIST = "/pricelist/search"

export const CLOTH_SEARCH = "/clothes/search"
// PRICELIST

export const ADD_PRICELIST = "/pricelist/create"
export const UPDATE_PRICELIST = "/pricelist/update"
export const BULK_UPDATE_PRICE = "/pricelist/bulkUpdate"
export const DELETE_PRICELIST = "/pricelist/delete"
export const GET_LAUNDRIESLIST = "/laundries/search"

//area list
export const GET_AREA_LIST = "/areas/search"
export const UPDATE_AREA_LIST = "/areas/update"


export const ADD_NEW_AREA = "/areas/create"


//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers/search"
export const GET_CUSTOMERS_DETAILS_UI = "/customers/searchDetails"
export const GET_CUSTOMERS_LOCATION = "/customers/customerLocation"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"
export const SEARCH_ALL_CUSTOMERS = "/customers/searchAllCustomers"
export const OTPLIST_CUSTOMERS = "/customers/customersOtpList"
export const GET_OTP = "/customers/viewotp"

//SHOPS
export const GET_SHOPS = "/shops"

export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//ORDERS
export const REAL_TIME_DETAILS = "/orders/realtime_details"
export const TOP4_DETAILS = "/orders/top4_stats"
export const ONGOING_ORDERS = "/orders/ongoing_orders"
export const ORDER_HISTORY = "/orders/order_history"
export const All_ORDERS = "/orders/search"
export const REFUND_ORDERS = "/orders/refundOrders"
export const All_ORDERS_SEARCH = "/orders/searchAllOrders"
export const COMPLETED_ORDERS = "/orders/completed"
export const RETURNED_ORDERS = "/orders/returned"
export const WHATSAPP_ORDERS = "/orders/whatsappOrders"
export const UPDATE_ORDERS_INVOICE = "/orders/update"
export const UPDATE_CUSTOMERS_BAG = "/customers/update"
export const UPDATE_ORDER_PAY_WITH_CASH = "/orders/payWithCash"
export const REMOVE_BAG_FEE = "/orders/removeBagFee"
export const FETCH_CANCELLED_ORDERS = "/orders/listCanceled"
export const FETCH_ORDER_BY_ID = "/orders/searchByFilter"

//ANALYTICS-HOME
export const GET_APP_REGISTRATIONS = "/dashboard/appRegistrations"
export const GET_UNIQUE_ORDERS = "/dashboard/uniqueCustomerOrders"
export const GET_MONTHLY_ORDERS = "/dashboard/monthlyOrderCount"
export const GET_MONTHLY_GMV = "/dashboard/monthlyGMVData"
export const GET_DAILY_LAUNDRY = "/dashboard/dailyLaundryReport"
export const GET_DAILY_ONLINE = "/dashboard/dailyOnlineReport"
export const GET_MONTHLY_AVGBASKET = "/dashboard/monthlyAvgBasket"
export const GET_MONTHLY_AVGSUBSCRIPTION = "/dashboard/monthlyAvgSubscription"
export const GET_MONTHLY_SUBSCRIBER = "/dashboard/monthlySubscriber"
export const GET_MONTHLY_SEGMENT = "/dashboard/basketSegment"
export const GET_SALES_GROWTH = "/dashboard/salesGrowth"



//Analytics Charts
export const ANALYTICS_REGISTRATION_SUMMARY = "/analytics/registrations"
export const ANALYTICS_BAG_CUSTOMERS = "/analytics/bagCustomers"
export const ANALYTICS_ORDER_COUNTS = "/dashboard/countOrders"
export const ANALYTICS_UNIQUE_CUSTOMERS = "/dashboard/uniqueCustomers"

//Support Tools
export const GET_USER_ANALYTICS = "analytics/userAnalytics"
export const GET_CUSTOMERS_DETAILS_BY_ID = "/customers/searchDetailsByID"

//USERS
export const SEARCH_USERS = "/users/search"
export const USERS_LIST_ALL = "/users/getUsersList"
export const POST_ADD_USER = "/users/register"
export const POST_UPDATE_USER = "/users/update"
export const GET_USER = "users/get"
export const USER_DELETE = "users/delete"
export const USER_SEARCH = "users/search"

export const ADD_USER = "/users/create"

//Laundries
export const LAUNDRIES_LIST_ALL = "/laundries/listall"
// export const POST_ADD_LAUNDRY = "/laundries/register"
// export const POST_UPDATE_LAUNDRY = "/laundries/update"
// export const GET_LAUNDRY = "laundries/get"

//Areas
export const AREA_LIST_ALL = "/areas/listall"
export const POST_ADD_AREA = "/areas/register"
export const POST_UPDATE_AREA = "/areas/update"
export const GET_AREA = "areas/get"
export const POST_REORGANIZE_AREA = "areas/reorganize"



//LAUNDRY
export const POST_ADD_LAUNDRY = "/laundries/create"
export const POST_UPDATE_LAUNDRY = "/laundries/update"
export const GET_LAUNDRY = "/laundries/search"
export const DELETE_LAUNDRY = "/laundries/delete"
export const POST_AREA_LIST = "/areas/search"
export const FETCH_ALL_LAUNDRY_REVENUE = "/laundries/allLaundryRevenue"

//CLOTHES
export const GET_CLOTHES = "/clothes/search"
export const CREATE_CLOTH = "/clothes/create"
export const UPDATE_CLOTH = "/clothes/update"

//REASONS
export const GET_REASONS = "/reasons/search"
export const CREATE_REASON = "/reasons/create"
export const UPDATE_REASON = "/reasons/update"

// DRIVER
export const GET_DRIVER = "drivers/search"
export const GET_DRIVER_LOCATION = "/drivers/location"
export const GET_B2B_CUSTOMER = "/b2bcustomers/search"


// DRIVER
export const GET_HOLIDAY_LIST = "/holidays/search"
export const CREATE_HOLIDAY = "/holidays/create"
export const UPDATE_HOLIDAY = "/holidays/update"

//Redeem Packages
export const GET_REDEEM_PACKAGES = "/redeemPackages/search"
export const CREATE_REDEEM_PACKAGE = "/redeemPackages/create"
export const UPDATE_REDEEM_PACKAGE = "/redeemPackages/update"

//Subscription Packages
export const GET_PACKAGES = "/packages/search"
export const CREATE_PACKAGE = "/packages/create"
export const UPDATE_PACKAGE = "/packages/update"


//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

// SLEDERS 
export const GET_SLIDER = "/slider/search"
export const CREATE_SLIDER = "/slider/create"
export const UPDATE_SLIDER = "/slider/update"
export const DELETE_SLIDER = "/slider/delete"

//dashboard charts data
//export const GET_WEEKLY_DATA = "/reports/getDashboardOrderDetailsGMVByMonth"
//export const GET_YEARLY_DATA = "/reports/getDashboardOrderDetailsGMVByRegion"
//export const GET_MONTHLY_DATA = "/reports/getDashboardOrderDetails"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"


// Permission
export const GET_PERMISSION = "/roles/search"
export const GET_ALL_PERMISSIONS = "/permissions/getAll"
export const GET_ALL_MODULE = "/permissions/search"
export const UPDATE_PERMISSION = "/roles/update"
export const CREATE_MODULE = "/permissions/create"
export const UPDATE_MODULE = "/permissions/update"
export const CREATE_ROLE = "/roles/create"

export const DATA_REPORT = "/reports/getReport"
export const GET_TIMESLOT_OF_DATE = "/timeslots/search"
export const FETCH_PICKUP_TIMESLOTS = "/timeslots/pickupTimeslots"
export const FETCH_DELIVERY_TIMESLOTS = "/timeslots/deliveryTimeslots"
export const CREATE_TIMESLOT = "/timeslots/create"
export const UPDATE_TIMESLOT = "/timeslots/update"

export const GET_SETTINGS = "/settings/search"
export const CREATE_SETTING = "/settings/create"
export const UPDATE_SETTING = "/settings/update"

export const GET_DRIVERS = "/drivers/search"
export const ADD_DRIVERS = "/drivers/create"
export const UPDATE_DRIVERS = "/drivers/update"
export const DELETE_DRIVERS = "/drivers/delete"

export const GET_COUPONS = "/coupons/search"
export const UPDATE_COUPONS = "/coupons/update"
export const ADD_COUPONS = "/coupons/create"

//B2B Customer
export const GET_B2B_CUSTOMERS = "/b2bCustomers/search"
export const POST_ADD_B2B = "/b2bCustomers/create"
export const POST_UPDATE_B2B = "/b2bCustomers/update"


//B2B Product
export const GET_B2B_PRODUCTS = "/b2bproducts/search"
export const POST_ADD_B2BPRODUCT = "/b2bproducts/create"
export const POST_UPDATE_B2BPRODUCT = "/b2bproducts/update"

// Loyality Points

export const GET_LOYALITY_POINTS = "/loyaltyPoints/searchWeb"
export const UPDATE_LOYALITY_POINTS = "/loyaltyPoints/update"

// Carwash Orders
export const GET_CARWASH_ORDERS = "/carwashorders/search"
export const UPDATE_CARWASH_ORDERS = "/carwashorders/update"

//SUBSCRIPTION PACKAGE
export const GET_SUBSCRIPTION_PACKAGES = "/packages/search"
export const CREATE_SUBSCRIPTION_PACKAGES = "/packages/create"
export const UPDATE_SUBSCRIPTION_PACKAGES = "/packages/update"

// Service
export const GET_SERVICES = "/services/search"
export const UPDATE_SERVICE = "/services/update"

// Notification Text
export const GET_NOTIFICATION_TEXTS = "/notificationTexts/search"
export const CREATE_NOTIFICATION_TEXTS = "/notificationTexts/create"
export const UPDATE_NOTIFICATION_TEXTS = "/notificationTexts/update"


// Announcement

export const GET_ANNOUNCEMENT = "/annoucement/search"
export const CREATE_ANNOUNCEMENT = "/annoucement/create"
export const UPDATE_ANNOUNCEMENT = "/annoucement/update"

// Invoice Log
export const GET_INVOICE_LOG = "/invoice-logs/search"