import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import ClotheChanges from './ClothChanges';


//To replace variable names with more readable names
const fieldNameMap = {
  bills: { label: 'Laundry Bill', type: 'array' },
  billAmount: { label: 'Bill Amount', type: 'currency' },
  grandTotal: { label: 'Grand Total', type: 'currency' },
  subTotal: { label: 'Sub Total', type: 'currency' },
  total: { label: 'Total', type: 'currency' },
  vatAmt: { label: 'VAT Amount', type: 'currency' },
  discount: { label: 'Discount', type: 'currency' },
  clothes: { label: 'Clothes', type: 'array' },
  iCount: { label: 'Iron Count', type: 'count' },
  wCount: { label: 'Wash Count', type: 'count' },
  dCount: { label: 'Dry Clean Count', type: 'count' },
  status: { label: 'Status', type: 'string' },
  updatedBy: { label: 'Updated By', type: 'string' },
  name: { label: 'Name', type: 'string' },
  email: { label: 'Email', type: 'string' },
  cancellationReason: { label: 'Cancellation Reason', type: 'string' },
  approvedBy: { label: 'Approved By', type: 'string' },
  mobile: { label: 'Mobile Number', type: 'string' },
  reason: { label: 'Reason', type: 'string' },
  giftBalance: { label: 'Gift Balance', type: 'currency' },
  promocodeFreeMoney: { label: 'Promocode Free Money', type: 'currency' },
};

const AdditionalDataItem = ({ label, value }) => (
  <div className='invoiceLogs__additional-data_margin'>
    <strong>{label}:</strong> {value || 'N/A'}
  </div>
);

AdditionalDataItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const ExternalDataItem = ({ label, value, isUpdatedBy }) => (
  <div style={{ marginBottom: '0.5rem', marginLeft: isUpdatedBy ? "1.4rem" : '0' }}>
    <strong style={{ fontSize: "0.9rem" }}>{label}:</strong>
    <span style={{ fontSize: "0.9rem" }}>
      {isUpdatedBy && value.name ? value.name : value}
      {isUpdatedBy && value.email ? ` (${value.email})` : null}
    </span>
  </div>
);

ExternalDataItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isUpdatedBy: PropTypes.bool,
}

// Component to render JSON data in a more readable table format
const JsonTableViewer = ({ oldData, newData, clothes, transactionType, data }) => {
  // Determine title based on transactionType
  const getTitle = () => {
    switch (transactionType) {
      case 'pos_order':
        return 'Laundry Details';
      case 'customer_mobile':
        return 'Customer Mobile Details';
      case 'cancel_order':
        return 'Order Cancellation Details';
      case 'free_money':
        return 'Free Money Details';
      default:
        return 'Details';
    }
  };

  // Extract keys to render outside the table
  const externalKeys = [
    'reason', 
    'cancellationReason', 
    'updatedBy', 
    'approvedBy', 
    'suggestedBy'
  ];

  // Get values for external keys
  const externalData = externalKeys.reduce((acc, key) => {
    acc[key] = {
      oldValue: oldData?.[key],
      newValue: newData?.[key],
    };
    return acc;
  }, {});

  const additionalDataMap = {
    free_money: [
      { label: "Free Money Amount", value: data?.data?.amount },
      { label: "Free Money Given By", value: data?.updatedByDetails?.name },
      { label: "Approved By", value: data?.approvedBy },
      { label: "Reason", value: data?.notes },
      { label: "DateTime", value: data?.createdAt.split("T", 1) },

    ],
    cancel_order: [
      { label: "OrderID", value: data?.orderDetails?.orderID },
      { label: "Cancelled By", value: data?.updatedByDetails?.name },
      { label: "Approved By", value: data?.approvedBy },
      { label: "Reason", value: data?.notes },
      { label: "Cancelled On", value: data?.createdAt.split("T", 1) },
    ],
    customer_mobile: [
      { label: "Customer Name", value: data?.customerDetails?.name },
      { label: "Updated By", value: data?.updatedByDetails?.name },
      { label: "Approved By", value: data?.approvedBy },
      { label: "Reason", value: data?.notes },
      { label: "Updated at", value: data?.createdAt.split("T", 1) },
    ],
  };
  const additionalData = additionalDataMap[transactionType] || [];
  console.log("additionalData", additionalData)

  return (
    <Card>
      <CardBody>
        <h5>{getTitle()}</h5> {/* Render the title */}
        {oldData && newData ? renderComparisonTable(oldData, newData, clothes, transactionType) : 'No data available'}
      </CardBody>

      <div className="invoiceLogs__additional-data">
        {additionalData.length > 0 ? (
          additionalData.map((item, index) => (
            <AdditionalDataItem key={index} label={item.label} value={item.value} />
          ))
        ) :null}
      </div>
      {/* Render external keys */}
      {!data &&
        <div className="external-data">
          {externalKeys.map(key => {
            const oldValue = externalData?.[key]?.oldValue;
            const newValue = externalData?.[key]?.newValue;

            // Determine if we should render the new value or the old value
            if (newValue !== undefined && newValue !== null && newValue !== '') {
              return (
                <ExternalDataItem
                  key={key}
                  label={fieldNameMap?.[key]?.label || key}
                  value={newValue}
                  isUpdatedBy={key === 'updatedBy'}
                />
              );
            }

            // Render old value if new value is not available
            if (oldValue !== undefined && oldValue !== null && oldValue !== '') {
              return (
                <ExternalDataItem
                  key={key}
                  label={fieldNameMap?.[key]?.label || key}
                  value={oldValue}
                />
              );
            }

            // If both old and new values are not available, render nothing
            return null;
          })}
        </div>
      }
    </Card>
  );
};

JsonTableViewer.propTypes = {
  oldData: PropTypes.object,
  newData: PropTypes.object,
  clothes: PropTypes.arrayOf(PropTypes.object),
  transactionType: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default withTranslation()(JsonTableViewer);

const removeIdFields = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => removeIdFields(item)).filter(item => item !== null);
  }
  if (typeof data === 'object' && data !== null) {
    const newObj = {};
    Object.keys(data).forEach(key => {
      if (key !== '_id') {
        newObj[key] = removeIdFields(data?.[key]);
      }
    });
    return newObj;
  }
  return data;
};

const AuditLogsComparisonTableHeader = () => (
  <thead>
    <tr>
      <th className="auditLogs_table-header">Fields</th>
      <th className="auditLogs_table-header">Old Values</th>
      <th className="auditLogs_table-header">New Values</th>
    </tr>
  </thead>
);

const AuditLogsSimpleRow = ({ label, oldValue, newValue }) => (
  <tr>
    <td className={`auditLogs_bold auditLogs_table-cell-large`}>{label}</td>
    <td className="auditLogs_table-cell">{oldValue}</td>
    <td className="auditLogs_table-cell">{newValue}</td>
  </tr>
);

AuditLogsSimpleRow.propTypes = {
  label: PropTypes.string.isRequired,
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const AuditLogsPromocodeTable = ({ oldData, newData }) => (
  <Table bordered hover responsive size='sm' style={{ fontSize: '0.9rem' }}>
    <thead>
      <tr>
        <th className="auditLogs_table-cell">Fields</th>
        <th className="auditLogs_table-cell">Old Value</th>
        <th className="auditLogs_table-cell">New Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className={`auditLogs_bold auditLogs_table-cell`}>Promocode</td>
        <td className={`auditLogs_table-cell ${oldData?.['code'] !== newData?.['code'] ? 'red' : ''}`}>
          {oldData?.['code'] || '-'}
        </td>
        <td className={`auditLogs_table-cell ${!newData?.['code'] ? 'red' : oldData?.['code'] !== newData?.['code'] ? 'blue' : ''}`}>
          {newData?.['code'] || 'Promocode Removed'}
        </td>
      </tr>
    </tbody>
  </Table>
);

AuditLogsPromocodeTable.propTypes = {
  oldData: PropTypes.object,
  newData: PropTypes.object,
}

const AuditLogsBillsTable = ({ oldData, newData }) => (
  <Table bordered hover responsive size='sm' style={{ fontSize: '0.9rem' }}>
    <thead>
      <tr>
        <th className="auditLogs_table-cell">Fields</th>
        <th className="auditLogs_table-cell">Old Value</th>
        <th className="auditLogs_table-cell">New Value</th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(oldData).map(([subKey, subOldVal]) => {
        if (subKey === '_id') return null;

        const subNewVal = newData ? newData[subKey] : undefined;

        return (
          <tr key={subKey}>
            <td className={`auditLogs_bold auditLogs_table-cell`}>{fieldNameMap[subKey]?.label || subKey}</td>
            <td className="auditLogs_table-cell">{formatValue(subOldVal, oldData?.clothes, fieldNameMap?.[subKey]?.type)}</td>
            <td className="auditLogs_table-cell">{formatValue(subNewVal, newData?.clothes, fieldNameMap?.[subKey]?.type)}</td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

AuditLogsBillsTable.propTypes = {
  oldData: PropTypes.object,
  newData: PropTypes.object,
}

const renderComparisonTable = (oldData, newData, clothes, transactionType) => {
  // Remove _id fields from the data
  oldData = removeIdFields(oldData);
  newData = removeIdFields(newData);

  const allKeys = new Set([...Object.keys(oldData || {}), ...Object.keys(newData || {})]);

  // Exclude customerID if the transactionType is customer_mobile
  if (transactionType === "customer_mobile") {
    allKeys.delete('customerID');
  }

  // Define the keys to exclude from rendering in the table
  const keysToExclude = ['updatedBy', 'approvedBy', 'reason', 'cancellationReason', 'suggestedBy'];

  return (
    <Table bordered hover responsive style={{ border: '1px solid #454545' }}>
      <AuditLogsComparisonTableHeader />
      <tbody>
        {Array.from(allKeys).map((key) => {
          if (keysToExclude.includes(key)) {
            return null;
          }

          if (key === 'bills') {
            return (
              <React.Fragment key={key}>
                <tr>
                  <td colSpan="3" className={`auditLogs_bold auditLogs_table-row-highlight`}>
                    Laundry Bill
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <AuditLogsBillsTable oldData={oldData?.[key]??{}} newData={newData?.[key]??{}} />
                  </td>
                </tr>
              </React.Fragment>
            );
          }

          if (key === 'clothes') {
            const oldClothes = oldData?.[key] || [];
            const newClothes = newData?.[key] || [];

            return (
              <React.Fragment key={key}>
                <tr>
                  <td colSpan="3" className={`auditLogs_bold auditLogs_table-row-highlight`}>
                    Clothes
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <ClotheChanges oldClothes={oldClothes} newClothes={newClothes} clothes={clothes} />
                      </div>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          }

          if (key === 'promocode') {
            return (
              <React.Fragment key={key}>
                <tr>
                  <td colSpan="3" className={`auditLogs_bold auditLogs_table-row-highlight`}>
                    Promocode
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <AuditLogsPromocodeTable oldData={oldData?.[key]} newData={newData?.[key]} />
                  </td>
                </tr>
              </React.Fragment>
            );
          }

          const oldValue = key in oldData ? formatValue(oldData?.[key], clothes, fieldNameMap?.[key]?.type) : 'N/A';
          const newValue = key in newData ? formatValue(newData?.[key], clothes, fieldNameMap?.[key]?.type) : 'N/A';

          return (
            <AuditLogsSimpleRow key={key} label={fieldNameMap?.[key]?.label || key} oldValue={oldValue} newValue={newValue} />
          );
        })}
      </tbody>
    </Table>
  );
};




const formatValue = (value, clothes, fieldType) => {
  //Adding SR before currency values
  if (fieldType === 'currency') {
    return `SR ${value.toFixed(2)}`;
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      return 'No items';
    }

    return (
      <Table bordered hover responsive size="sm" className="clothes-table">
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Cloth Name</th>
            <th style={{ width: '16.67%' }}>Old Count</th>
            <th style={{ width: '16.67%' }}>New Count</th>
            <th style={{ width: '16.67%' }}>Change</th>
          </tr>
        </thead>
        <tbody>
          {value.map((item, index) => (
            <tr key={index}>
              <td style={{ width: '20%' }}>{getClothName(item.cloth, clothes)}</td>
              <td style={{ width: '16.67%' }}>{item.iCount || 0}</td> {/* Displaying only count fields */}
              <td style={{ width: '16.67%' }}>{item.wCount || 0}</td>
              <td style={{ width: '16.67%' }}>{item.dCount || 0}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  // Handle object type field
  if (typeof value === 'object' && value !== null) {
    return (
      <Table bordered hover responsive size="sm">
        <thead>
          <tr>
            <th style={{ width: '50%' }}>Fields</th>
            <th style={{ width: '50%' }}>Values</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(value).map(([key, val]) => (
            val ? (
              <tr key={key}>
                <td style={{ width: '50%' }}>{fieldNameMap?.[key]?.label || key}</td>
                <td style={{ width: '50%' }}>
                  {typeof val === 'number' && fieldNameMap?.[key]?.type === 'currency'
                    ? `SR ${val.toFixed(2)}`
                    : typeof val === 'object' && val !== null
                      ? JSON.stringify(val, null, 2)
                      : val.toString()}
                </td>
              </tr>
            ) : null
          ))}
        </tbody>
      </Table>
    );
  }

  // Default rendering for primitive values
  return typeof value === 'number' && fieldType === 'currency'
    ? `SR ${value?.toFixed(2)}`
    : value?.toString();
};


export const getClothName = (clothId, clothes) => {
  const cloth = clothes?.find(c => c._id == clothId);
  return cloth ? cloth?.nameEn : 'Unknown Cloth';
};